<template>
   <div class="ad" :class="{ dark: isDarkTheme, [fontSize]: !!fontSize }">
      <transition name="fade" mode="out-in">
         <router-view></router-view>
      </transition>
      <Modal v-model="isOpen" v-if="isShowModal" v-show="showModalCrutch" @input="input">
         <ImprovePrompt @skip="onSkip" @checked="handleChecked" />
      </Modal>
   </div>
</template>

<script>
import Modal from './components/UI/Modal.vue';
import { KEY_THEME, KEY_FONT_SIZE, DEFAULT_SIZE, SIZES } from './store/themeModes';
import './css/app.css';
import ImprovePrompt from './components/ImprovePrompt.vue';
import DeviceDetector from 'device-detector-js';
//import log from './logger/logger';

export default {
   provide() {
      return {
         changeThemeMode: this.changeThemeMode,
         changeFontSizeStyle: this.changeFontSizeStyle,
      };
   },

   components: { Modal, ImprovePrompt },

   data() {
      return {
         isOpen: false,
         // isDoNotShow: false, not used
         fontSize: 'default',
         isDarkTheme: false,
         showModalCrutch: false, //!crutch for modal window to not be visible on background for a few milliseconds
         wrapperContentClasses: ['clickOutside'],
      };
   },
   created() {
      const deviceInfo = new DeviceDetector().parse(navigator.userAgent);

      localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));

 //     let timeSpent = 0;

     // setInterval(() => {
   //      timeSpent += 30;
         //log(this.$http, { message: `stays on the site in seconds ${timeSpent}`, level: 'info' });
    //  }, 30000);

      window.addEventListener('beforeunload', () => {
       //  log(this.$http, { message: 'ended a session', level: 'info' });
      });
   },
   async mounted() {
      const habitsPrompt = localStorage.getItem('isShowImprovePrompt');

      if (habitsPrompt === 'false') {
         this.$store.commit('app/PWA_INSTALL_PROMT_CAN_BE_SHOWED');
      }

      if (habitsPrompt === 'true') {
         try {
            this.isOpen = JSON.parse(localStorage.getItem('isShowImprovePrompt'));
         } catch (e) {
            this.isOpen = true;
         }
      } else {
         this.isOpen = true;
      }
      try {
         this.isDarkTheme = JSON.parse(localStorage.getItem(KEY_THEME));
      } catch {
         this.isDarkTheme = false;
      }
      try {
         const res = JSON.parse(localStorage.getItem(KEY_FONT_SIZE));
         if (SIZES.includes(res)) this.fontSize = JSON.parse(localStorage.getItem(KEY_FONT_SIZE));
      } catch {
         this.fontSize = DEFAULT_SIZE;
      }
      //! crutch for modal window to not be visible on background for a few milliseconds
      setTimeout(() => {
         this.showModalCrutch = true;
      }, 1000);

      //end crutch
      // document.addEventListener('click', this.closeInput);
      //log(this.$http, { message: 'started a session', level: 'info' });

      const customer = await this.$http.get('payment/customer');
      console.log("🚀 ~ mounted ~ customer:", customer)
      const promocodes = await this.$http.get('payment/promocodes');
      this.$store.commit(`customer/UPDATE_CUSTOMER`, customer.data);
      this.$store.commit(`customer/UPDATE_PROMOCODES`, promocodes.data);
   },

   computed: {
      isLoginRoute() {
         return this.$route.name;
      },
      profile() {
         return this.$store.getters['profile/getProfile'];
      },

      isShowModal() {
         return (
            !this.profile.Disturb &&
            this.$route.name !== 'login' &&
            localStorage.getItem('isShowImprovePrompt') !== 'false'
         );
         //return !this.profile.Disturb && localStorage.getItem('isShowImprovePrompt') !== 'false';
      },
   },

   methods: {
      handleChecked(val) {
         this.isDoNotShow = val;
         //localStorage.setItem('isShowImprovePrompt', false);
         //this.$store.commit('app/PWA_INSTALL_PROMT_CAN_BE_SHOWED'); //! crutch for pwa install prompt to be showed after closing improve prompt
      },
      onSkip() {
         localStorage.setItem('isShowImprovePrompt', false);

         if (this.isDoNotShow) {
            this.$http.put('resources/profile', { Disturb: this.isDoNotShow });
         }
         this.isOpen = false;
         setTimeout(() => {
            this.$store.commit('app/PWA_INSTALL_PROMT_CAN_BE_SHOWED'); //! crutch for pwa install prompt to be showed after closing improve prompt
         }, 1000);
      },
      changeThemeMode(isDarkMode) {
         if (typeof isDarkMode !== 'boolean') return;
         this.isDarkTheme = isDarkMode;
      },
      changeFontSizeStyle(fontSize) {
         if (!SIZES.includes(fontSize)) return;

         this.fontSize = fontSize;
      },

      input() {
         setTimeout(() => {
            this.$store.commit('app/PWA_INSTALL_PROMT_CAN_BE_SHOWED'); //! crutch for pwa install prompt to be showed after closing improve prompt
         }, 1000);
      },
   },
};
</script>

<style>
@import '../node_modules/sw-ui-kit/dist/styles.css';
</style>

<style>
@media print {
   html,
   body {
      display: none;
   }
}
.ad {
   height: 100%;
   width: 100%;
}

input {
   -webkit-appearance: none;
}

#installApp {
   position: fixed;
   background-color: var(--bg-install-element);
   bottom: 164px;
   left: 50%;
   transform: translate(-50%, -50%);
   font-size: 1.15rem;
   z-index: 20000;
   font-size: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 16px;
   max-width: 580px;
   width: 91%;
   border: 0.5px solid #b9babe;
}
#installApp.top {
   bottom: unset;
   transform: translate(-50%, 30%);
   top: 0;
}
#installApp svg {
   width: 12px;
   height: 12px;
   fill: black;
}
#installApp .content {
   display: flex;
   align-items: center;
   font-weight: 700;
   padding: 8px;
   margin-right: 10px;
   justify-content: center;
}
#installApp .mobail {
   margin-right: 0px;
}
#installApp .ios {
   bottom: unset;
}
#installApp .logo {
   max-width: 40px;
   width: 100%;
}
#installApp .mobail-logo {
   max-width: 180px;
   width: 100%;
}
#installApp .install {
   padding: 8px 16px;
   margin-left: 10px;
   margin-right: 5px;
}
#installApp .close {
   position: absolute;
   top: 5px;
   right: 10px;
}
@media (max-width: 600px) {
   #installApp {
      font-size: 1rem;
      bottom: unset;
      top: 48%;
      max-height: 522px;
      max-width: 358px;
   }
   #installApp .content {
      text-align: center;
   }
   #installApp .logo {
      display: none;
   }
   #installApp .install {
      display: flex;
      justify-content: center;
   }
}
.fade-enter {
   opacity: 0;
   transform: translateY(10%);
}
.fade-enter-active {
   transition: all 0.4s ease;
}
.fade-leave-active {
   transition: all 0.4s ease;
   opacity: 0;
   transform: translateY(10%);
}
h1,
h2,
html,
body,
ul,
p {
   margin: 0;
   padding: 0;
}
ul {
   list-style: none;
}
button {
   border: none;
   background-color: transparent;
   padding: 0;
   margin: 0;
}
*,
*::before,
*::after {
   box-sizing: border-box;
}
*:focus {
   outline: none;
}
*:active {
   outline: none;
}
p:hover,
h1:hover,
h2:hover,
h3:hover,
h4:hover,
h5:hover,
h6:hover {
   cursor: default;
}
button:focus,
button:active {
   outline: none;
}
body {
   font-weight: 300;
   font-size: 1rem;
}
body,
html {
   height: 100%;
}
* {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
input,
input:before,
input:after {
   -webkit-user-select: initial;
   -khtml-user-select: initial;
   -moz-user-select: initial;
   -ms-user-select: initial;
   user-select: initial;
}
.wrapper {
   height: 100%;
}
.wrapper > div {
   height: inherit;
}
</style>
