import mutations from '../mutation-types';
import http from '../../plugins/http';
import actions from '../action-types';
//import router from '../../router/router';
import { JWT_EXPIRED, JWT_MUST_PROVIDED } from '../apiErrors';

const store = {
   namespaced: true,

   state: {
      profile: {
         discount: 0,
         email: '',
         first_name: '',
         img: '',
         last_name: '',
         locale: '',
         phone: '',
         subscribtion: '',
         subscribtion_time: '',
         themeStyles: '',
      },

      isProfileLoaded: false,

      profileItemEditing: false,

      subscriptions: [],

      isLogOutOpen: false,

      coupon: null,

      couponError: '',
   },

   getters: {
      getProfile: state => state.profile,

      getProfileSubscription: state => state.profile.subscribtion,

      getProfileItemEditing: state => state.profileItemEditing,

      getProfileLoaded: state => state.isProfileLoaded,

      getSubscriptions: state => state.subscriptions,

      getIsLogOutOpen: state => state.isLogOutOpen,

      getCouponError: state => state.couponError,

      getCoupon: state => state.coupon,
   },

   mutations: {
      [mutations.UPDATE_PROFILE](state, data) {
         for (let key in data) {
            state.profile[key] = data[key];
         }
      },

      [mutations.SET_PROFILE](state, data) {
         state.profile = data;
      },

      [mutations.PROFILE_ITEM_EDITING](state, data) {
         state.profileItemEditing = data;
      },

      [mutations.SET_PROFILE_LOADED](state, data) {
         state.isProfileLoaded = data;
      },

      [mutations.UPDATE_SUBSCRIPTIONS](state, data) {
         state.subscriptions = data;
      },

      [mutations.SET_LOG_OUT_OPEN](state, data) {
         state.isLogOutOpen = data;
      },

      [mutations.SET_COUPON](state, data) {
         state.coupon = data;
      },

      [mutations.UPDATE_ERROR_COUPON](state, data) {
         state.couponError = data;
      },
   },

   actions: {
      async updateProfile({ state, commit }) {
         commit(`advice/${mutations.UPDATE_LOADING}`, true, { root: true });
         let profilToUpdate = { ...state.profile };
         delete profilToUpdate.email;
         delete profilToUpdate.phone;
         delete profilToUpdate.google_id;
         delete profilToUpdate.facebook_id;
         delete profilToUpdate.twitter_id;
         delete profilToUpdate.verify_email;
         delete profilToUpdate.verify_phone;
         delete profilToUpdate.subscribtion;
         delete profilToUpdate.subscription;
         delete profilToUpdate.subscribtion_time;
         delete profilToUpdate.uuid;
         delete profilToUpdate._id;
         delete profilToUpdate.__v;
         delete profilToUpdate.canceled;
         delete profilToUpdate.coupons;
         delete profilToUpdate.coupon_cycles;
         await http.put('resources/profile', profilToUpdate);
         commit(`advice/${mutations.UPDATE_LOADING}`, false, { root: true });
      },

      async [actions.FETCH]({ commit }) {
         try {
            commit(`advice/${mutations.UPDATE_LOADING}`, true, { root: true });
            let data = await http.post('resources/profile');
            commit(mutations.SET_PROFILE, data.data);
            commit(mutations.SET_PROFILE_LOADED, true);
            commit(`advice/${mutations.UPDATE_LOADING}`, false, { root: true });
         } catch (err) {
            if (
               err?.data?.message?.message?.message === JWT_EXPIRED ||
               err?.data?.message?.message?.message === JWT_MUST_PROVIDED
            ) {
               // router.push({ name: 'app-login' });
            } else if (err.status === 500) {
               window.reload();
            } else {
               // router.push({ name: 'app-login' });
            }
         }
      },

      async fetchSubscriptions({ commit }) {
         let data = await http.post('resources/subscribtions');
         commit(mutations.UPDATE_SUBSCRIPTIONS, data.data.subscriptions);
      },

      async changeSubscription({ dispatch }, name) {
         try {
            await http.put('resources/profile/subscribtion', { name });
            if (navigator.serviceWorker.controller) {
               navigator.serviceWorker.controller.postMessage({
                  type: 'clearProfile',
               });
               navigator.serviceWorker.controller.postMessage({
                  type: 'clearCategories',
               });
            }
            dispatch(actions.FETCH);
         } catch (e) {
            console.log(e);
         }
      },

      async fetchCoupon({ commit }, coupon) {
         try {
            const response = await http.put('resources/profile/coupon', { coupon });
            commit(mutations.SET_COUPON, response.data.coupon);
            commit(mutations.UPDATE_ERROR_COUPON, '');
         } catch (e) {
            if (e?.data?.message) {
               commit(mutations.UPDATE_ERROR_COUPON, e.data.message);
            }
         }
      },
   },
};

export default store;
