//import actions from '../action-types';

const store = {
    namespaced: true,
 
    state: {
       customer: {},
       promocodes: {}, 
    },
 
    getters: {
       getCustomer: state => state.customer,
       getSubscription: state => state.customer?.subscriptions?.data[0],
       getPromocodes: state => state.promocodes,
       isTrial: state => state.customer?.subscriptions?.data[0]?.status === 'trialing' ? true : false,
    },
 
    mutations: {
       UPDATE_CUSTOMER(state, data) {
          state.customer = data;
       },
       UPDATE_PROMOCODES(state, data) { 
          state.promocodes = data;
       }
    },
 
    actions: {
      removePromocodeFromList(index) {
         
         index.commit('UPDATE_PROMOCODES', this.state.customer.promocodes.data.splice(index, 1));
      }
    },
 };
 
 export default store;
 