<template>
	<div class="modal__content__box">
		<div class="modal__content">
			<div class="img__modal"><img src="../assets/img/girlModal.svg" alt="" /></div>
			<div class="item__modal">
				<Typography size="bigger" weigth="weigth">{{ $t('ImprtovePrompt.title') }}</Typography>
			</div>
			<div class="paragraf__modal">
				<Typography
					>We want to share tips on useful sleep habits that will allow you to improve your quality of sleep and
					well-being</Typography
				>
			</div>
			<div class="button_check__modal">
				<router-link :to="{ name: 'habits' }">
					<SWButton variant="primary" @click="$emit('skip')" size="big">Check it out</SWButton>
				</router-link>
			</div>
			<div class="button_skip__modal">
				<SWButton variant="secondary" size="big" @click="$emit('skip')">Skip</SWButton>
			</div>
			<div class="checkbox__modal">
				<SWCheckbox v-model="isDoNotShow"> Do not show this again </SWCheckbox>
			</div>
		</div>
	</div>
</template>

<script>
import Typography from './UI/Typography.vue';
import { SWButton, SWCheckbox } from 'sw-ui-kit';
export default {
	components: {
		Typography,
		SWButton,
		SWCheckbox,
	},
	data() {
		return { isDoNotShow: false };
	},

	watch: {
		isDoNotShow() {
			this.$emit('checked', this.isDoNotShow);
		},
	},
};
</script>

<style scoped>
.modal__content__box {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.modal__content {
	display: flex;
	flex-direction: column;
	max-width: 337px;
}
.button_check__modal {
	width: 100%;
}
.button_skip__modal {
	width: 100%;
}
.img__modal {
	margin-bottom: 24px;
}
.item__modal {
	margin-bottom: 32px;
}
.paragraf__modal {
	margin-bottom: 32px;
}
.button_check__modal {
	margin-bottom: 18px;
}
.button_skip__modal {
	margin-bottom: 40px;
}
</style>

<style>
.input-checkbox {
	width: unset !important;
	height: unset !important;
}
.input-checkbox .check.option {
	display: flex;
	align-items: center;
	justify-content: center;
}

.input-checkbox .check.option .check__box {
	margin-right: 11px;
}
</style>
