import { advicesRouter } from '../childs/advices';

import VueRouter from 'vue-router';

const routeApp = new VueRouter({
   mode: 'history',
   routes: [
      {
         path: '/registration',
         beforeEnter() {
            window.location.href = process.env.VUE_APP_QUIZ_URL;
         },
      },
      {
         path: '/forgot',
         name: 'forgot',
         component: () => import('../../views/PasswordRecovery/PasswordRecovery.vue'),
      },
      {
         path: '/reset-by-email',
         name: 'reset-by-email',
         component: () => import('../../views/PasswordRecovery/ResetByEmail.vue'),

      },
      {
         path: '/recovery',
         name: 'recovery',
         component: () => import('../../views/PasswordRecovery/RecoveryByEmailOrPhone.vue'),

      },
      {
         path: '/reset-by-phone',
         name: 'reset-by-phone',
         component: () => import('../../views/PasswordRecovery/ResetByPhone.vue'),

      },
      {
         path: '/recovery-success',
         name: 'recovery-success',
         component: () => import('../../views/PasswordRecovery/RecoverySuccess.vue'),

      },        
      {
         path: '/login',
         name: 'login',
         component: () => import('../../views/AdvicesApp/TheLogin.vue'),
         //params: { hideLayout: true },
      },
      
      {
         path: '/',
         name: 'app',
         component: () => import('../../views/AdvicesApp/TheAdvices.vue'),
        // redirect: { name: 'app-main' },
         children: advicesRouter,
      },

      {
         path: '/*',
         name: '404',
         component: () => import('../../views/The404Page.vue'),
      },
   ],
});

export default routeApp;
