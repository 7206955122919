//import actions from '../action-types';

const store = {
   namespaced: true,

   state: {
      currentCategory: { id: null, name: null, group: null, predefined: false },
      categoryListHaschanges: false, //!crutch for update category list when add or remove list item
      customLists: [],
      closePlayer: false,
      installPWAcanBeShowed: false,
   },

   getters: {
      getCurrentCategory: state => state.currentCategory,
      getCategoryListHaschanges: state => state.categoryListHaschanges, //!crutch for update category list when add or remove list item
      getCustomLists: state => state.customLists,
      getClosePlayer: state => state.closePlayer,
      installPWAcanBeShowed: state => state.installPWAcanBeShowed,
   },

   mutations: {
      UPDATE_CURRENT_CATEGORY(state, data) {
         state.currentCategory = data;
      },
      UPDATE_CATEGORY_LIST_HAS_CHANGES(state) { //!crutch for update category list when add or remove list item
         state.categoryListHaschanges = !state.categoryListHaschanges;
      },
      UPDATE_CUSTOM_LISTS(state, data) {
         state.customLists = data;
      },
      TOGGLE_CLOSE_PLAYER(state) {
         state.closePlayer = !state.closePlayer;
      },
      PWA_INSTALL_PROMT_CAN_BE_SHOWED(state){
         state.installPWAcanBeShowed = true;
      }
   },

   actions: {},
};

export default store;
