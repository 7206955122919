import axios from 'axios';
//import router from '../router/router.js';
import remoteOrigin from '../store/remoteOrigin.js';
import router from '../router/router.js';

const http = axios.create({
   //baseURL: 'http://localhost:7000/',
   baseURL: remoteOrigin,
});

http.interceptors.request.use(
   function (config) {
      if (!localStorage.user_token) {
         let url = new URL(window.location);
         if (url.searchParams.get('token')) {
            localStorage.user_token = url.searchParams.get('token');
            localStorage.social_img = url.searchParams.get('img');
            window.location.replace(window.location.origin);
         }
      }
      config.headers.Authorization = 'Bearer ' + localStorage.user_token;
      return config;
   },
   function (error) {
      return Promise.reject(error);
   },
);

//const allowedUnauthorizedRoutes = ['login','forgot'];

http.interceptors.response.use(
   res => {
      return res;
   },
   err => {


      if (err?.response?.data?.message) {
         console.log("response", err?.response?.data?.message)
         console.log(router?.history?.current?.name);
/*          if(allowedUnauthorizedRoutes.includes(router?.history?.current?.name && err?.response?.request?.responseURL?.includes('resources/log'))){
            console.log('ALLOWED');
            return Promise.reject(err.response);
         }  */
         if ( 
            err.response.status === 401 ||
            err.response?.data?.message?.message?.message === 'jwt expired' ||
            err.response?.data?.message?.message?.message === 'jwt must be provided' ||
            err.response?.data?.message?.message?.message === 'jwt malformed'
         ) {
            console.log('GO PUSH!');
              router.push({
               name: 'login',
            });  
         } else {
            return Promise.reject(err.response);
         }
      }
      return Promise.reject(err);
   },
);

export default http;
