<template>
   <div class="modal__container" v-if="value">
      <div
         class="modal__wrapper"
         :class="wrapperContentClasses"
         ref="backdrop"
         tabindex="0"
         @keydown.esc="onClose"
         @click.stop="onClose"
      >
         <div class="modal__content" :class="modalContentClasses" @click.stop>
            <slot><Typography size="small" weigth="weigth" lineheigth="biggerHeigth"></Typography></slot>
         </div>
      </div>
   </div>
</template>

<script>
import Typography from './Typography.vue';

export default {
   components: {
      Typography,
   },
   props: {
      value: {},
      maxWidth: {
         type: Boolean,
         default: false,
      },
      modalContentClasses: {
         type: Array,
      },
      wrapperContentClasses: {
         type: Array,
      },
   },
   computed: {},
   mounted() {
      if (this.value) {
         this.$nextTick(() => {
            this.$refs.backdrop.focus();
         });
      }
   },
   watch: {
      value() {
         if (this.value) {
            this.$nextTick(() => {
               this.$refs.backdrop.focus();
            });
         }
      },
   },
   methods: {
      onClose() {
         this.$emit('input', false);
      },
   },
   emits: ['close', ''],
};
</script>

<style scoped>
.modal__content {
   padding: 56px 0px 20px 0px;
   transition: all 0.3s ease;
   max-width: 500px;
   width: 100%;
   text-align: center;
   z-index: 1000;
   border-radius: 10px;
   background-color: var(--bg-modal-dark);
}
.modal__wrapper {
   display: flex;
   justify-content: center;
   margin: 0 auto;
   width: 90%;
   height: auto;
   z-index: 600;
}
.modal__container {
   position: fixed;
   z-index: 500;
   top: 0;
   left: 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   transition: opacity 0.3s ease;
   width: 100%;
   height: 100%;
   background-color: var(--bg--modal-rgba);
}
@media screen and (max-width: 415px) {
   .modal__container {
      top: 65px;
   }
}
</style>
