<template>
   <component :is="tag" :class="className">
      <slot></slot>
   </component>
</template>

<script>
const SMALL_SIZE = 'small';
const DEFAULT = 'default';
const BIG_SIZE = 'big';
const BIGGER_SIZE = 'bigger';
const BIGGEST_SIZE = 'biggest';
const BIG_WEIGHT = 'weigth';
const MEDIUM_WEIGHT = 'medium';
const CURRENT_COLOR = 'darkColor';
const CURSOR_POINTER = 'pointer';

export default {
   props: {
      tag: {
         type: String,
         default: 'p',
      },
      size: {
         type: String,
         default: 'small',
      },
      weigth: {
         type: String,
      },
      color: {
         type: String,
      },
      pointer: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      className() {
         return {
            fontSize: true,
            default: this.size === DEFAULT,
            smallSize: this.size === SMALL_SIZE,
            bigger: this.size === BIGGER_SIZE,
            biggest: this.size === BIGGEST_SIZE,
            bigSize: this.size === BIG_SIZE,
            weigth: this.weigth === BIG_WEIGHT,
            medium: this.weigth === MEDIUM_WEIGHT,
            darkColor: this.color === CURRENT_COLOR,
            cursorePointer: this.pointer === CURSOR_POINTER,
         };
      },
   },
};
</script>

<style>
.fontSize {
   color: var(--typograohy-color);
   line-height: 1.3em;
   font-weight: 400;
}

.ad.default .fontSize {
   font-size: 16px;
}

.ad.small .fontSize {
   font-size: 14px;
}
.ad.big .fontSize {
   font-size: 18px;
}

.smallSize {
   font-size: 14px;
}

.ad.small .fontSize.bigger {
   font-size: 20px;
}

.ad.default .fontSize.bigger {
   font-size: 24px;
}
.ad.big .fontSize.bigger {
   font-size: 26px;
}

.ad.small .fontSize.biggest {
   font-size: 46px;
}
.ad.default .fontSize.biggest {
   font-size: 56px;
}
.ad.big .fontSize.biggest {
   font-size: 58px;
}

.biggest {
   font-size: 56px;
}
.bigSize {
   font-size: 18px;
}
.weigth {
   font-weight: 700;
}
.medium {
   font-weight: 500;
}
.cursorePointer {
   cursor: pointer;
}
</style>
