import mutations from '../mutation-types';
import actions from '../action-types';
import http from '../../plugins/http';
import router from '../../router/router';
import { END_OF_TRIAL_SUBSCRIPTION, JWT_EXPIRED, JWT_MUST_PROVIDED } from '../apiErrors';
import { cloneDeep } from 'lodash';
import { CATEGORY_ALL } from '../categories';

const CATEGORY_TO_EXCLUDE = 'Habits';

const store = {
   namespaced: true,

   state: {
      title: 'Sleep Well',
      editMode: false,
      categories: [],
      tempUnvisible: [],
      hiddenCategories: [],
      advices: [],
      subcategories: [],
      hiddenSubcategories: [],
      currentSubcategories: {},
      subcategory: {},
      category: {},
      fontSize: '',
      isDataLoading: false,
      search: '',
      play: false,
      idOfPlayingAdvice: '',
      advicesHidden: [],
      isModalOpen: false,
      editItems: [],
      unvisible: [],
      unvisibleHabits: [],
      isCategoriesLoaded: false,
      isSerach: false,
      isHiddenMode: false,
      isHiddenModeHabits: false,
      isConfirmAdvices: false,
      isPlayerPlaying: false,
      updateScroll: true,
      playerIndex: null,
      isPlayDelimeter: false,
      isEditMenuOpen: false,
      isEditHabitsMenuOpen: false,
      userLists: [],
      selectedCategory: 'Awakening',
      selected: [],
      selectedHabits: [],
      categoryNameToEdit: null,
      profile: null,
      isCategoriesListOpen: false,
      listOfHiddenAdvicesForSelectedCategory: null,
      listOfHiddenAdvicesForSelectedCategoryHabits: null,
      visibleItems: null,
      visibleItemsHabits: null,
      habits: [],
      hiddenHabits: [],
      isHabitsRoute: false,
   },

   getters: {
      getProfile(state) {
         return state.profile;
      },

      getVisibleItems(state) {
         return state.visibleItems;
      },
      getVisibleItemsHabits(state) {
         return state.visibleItemsHabits;
      },

      getIsCategoriesListOpen(state) {
         return state.isCategoriesListOpen;
      },

      getSearch(state) {
         return state.search;
      },

      getCategoryNameToEdit(state) {
         return state.categoryNameToEdit;
      },

      getUserLists(state) {
         return state.userLists;
      },

      getCategoryToEdit(state) {
         return state.userLists.find(list => list.list_name === state.categoryNameToEdit);
      },

      getSelected(state) {
         return state.selected;
      },
      getSelectedHabits(state) {
         return state.selectedHabits;
      },

      getSelectedCategory(state) {
         return state.selectedCategory;
      },

      getIsEditMenuOpen(state) {
         return state.isEditMenuOpen;
      },

      //! crutch for habits!
      getIsEditMenuOpenHabits(state) {
         return state.isEditHabitsMenuOpen;
      },

      getCategoriesList(state) {
         return state.categories.filter(c => !c.parent && c.code !== CATEGORY_TO_EXCLUDE);
      },

      getHabitsList(state) {
         return state.categories.filter(c => !c.parent && c.code === CATEGORY_TO_EXCLUDE);
      },

      getLists(state) {
         if (!state.userLists) return [];
         const res = [];
         state.userLists.forEach(item => {
            if (item?.list_name && !res.includes(item?.list_name)) res.push(item.list_name);
         });
         return res.map(name => {
            return { name, value: name };
         });
      },

      getListsSelecting(state) {
         if (!state.userLists) return [];
         const res = [];
         state.userLists.forEach(item => {
            if (item.list_name && !res.some(el => el._id === item.list_id))
               res.push({ name: item.list_name, _id: item.list_id });
         });

         return res;
      },

      getListsAdvices(state) {
         if (!state.userLists) return [];
         const allUserAdvicesFromList = [];
         const res = [];

         state.userLists.forEach(item => {
            if (
               !res.some(el => el._id === item.list_id) &&
               item.list_name === state.selectedCategory &&
               item.text &&
               state.isHiddenMode == item.unvisible
            )
               allUserAdvicesFromList.push({ ...item, media: `/storage/audio/${item.media}`, _id: item.advice_id });
         });

         allUserAdvicesFromList.forEach(el => {
            if (state.categories.find(cat => cat.code.toLowerCase() === el.parent.toLowerCase() && !!cat.parent)) {
               let parent = state.categories.find(
                  cat => cat.code.toLowerCase() === el.parent.toLowerCase() && !!cat.parent,
               );
               let index = res.findIndex(resEl => resEl.code === parent.code);
               if (index === -1) {
                  res.push({ ...parent, list_id: el.list_id, advices: [el] });
               } else {
                  res[index].advices.push(el);
               }
            } else {
               res.push(el);
            }
         });
         return res;
      },

      getHabits(state) {
         let res = [];
         if (state.isHiddenModeHabits) {
            res = state.hiddenCategories.filter(c => c.parent === CATEGORY_TO_EXCLUDE);
         }
         res = state.categories.filter(c => c.parent === CATEGORY_TO_EXCLUDE);
         if (state.search) {
            res = res.filter(c => c.text.includes(state.search));
         }
         return res;
      },

      getHiddenCategoriesList(state) {
         return state.hiddenCategories;
      },

      getHiddenHabitsList(state) {
         return state.hiddenHabits;
      },

      getCategories(state) {
         if (state.search) {
            let filteredByAdvices = state.categories.filter(item =>
               Boolean(item?.text?.toLowerCase().match(state.search)),
            );
            let filteredCategories = state.categories.filter(item =>
               filteredByAdvices.some(advice => advice.parent === item.code && !item.parent),
            );
            return filteredCategories;
         }
         return state.categories.filter(item => !item.parent && item.title);
      },

      getIsConfirmAdvices: state => state.isConfirmAdvices,

      getHiddenMode: state => state.isHiddenMode,
      getHiddenModeHabits: state => state.isHiddenModeHabits,

      getAllCategories: state => state.categories,
      getAllHabitsCategories: state => state.habits,

      getCategoriesFilterSearch(state) {
         return state.categories.filter(item => Boolean(item?.text?.toLowerCase().match(state.search)));
      },

      getHiddenCategories: state => state.hiddenCategories.filter(item => !item.parent && item.title),

      getCategory: state => state.category,

      getSubcategories: state => {
         if (state.search) {
            let subcategoriesChilds = state.categories.filter(({ parent, text }) =>
               state.subcategories.some(({ code }) => {
                  return parent === code && Boolean(text?.toLowerCase().match(state.search));
               }),
            );
            let filteredSubcategories = state.categories.filter(({ code }) =>
               subcategoriesChilds.some(({ parent }) => code === parent),
            );
            return filteredSubcategories;
         }
         return state.subcategories;
      },

      getHiddenSubcategories: state => state.hiddenSubcategories,

      getAdvices: state => {
         let res = [];
         if (state.selectedCategory === CATEGORY_ALL) {
            state.advices.forEach(adviceList => {
               if (adviceList.code === CATEGORY_TO_EXCLUDE) return;
               res = [...res, ...adviceList?.advices];
            });
         } else {
            res = state.advices.filter(el => state.selectedCategory === el.code)?.[0]?.advices;
         }

         let advices = res ? cloneDeep(res) : [];
         if (!advices) return [];
         if (state.search) {
            advices = advices.filter(
               ({ text, advices: subAdvices }) =>
                  text.toLowerCase().split(new RegExp(`(${state.search.toLowerCase()})`, 'gi')).length > 1 ||
                  subAdvices?.some(
                     ({ text }) =>
                        text.toLowerCase().split(new RegExp(`(${state.search.toLowerCase()})`, 'gi')).length > 1,
                  ),
            );
            let indexes = [];
            advices.forEach((el, index) => {
               if (el.advices) {
                  indexes.push(index);
               }
            });
            indexes.forEach(index => {
               if (advices?.[index]?.advices)
                  advices[index].advices = advices?.[index]?.advices?.filter(
                     ({ text }) =>
                        text.toLowerCase().split(new RegExp(`(${state.search.toLowerCase()})`, 'gi')).length > 1,
                  );
            });
         }
         return advices;
      },

      getHiddenAdvices: state => {
         if (state.search) {
            return state.advicesHidden.filter(
               ({ text }) =>
                  text.toLowerCase().split(new RegExp(`(${state.search.toLowerCase()})`, 'gi')).length > 1 ||
                  state?.advicesHidden?.some(
                     text.toLowerCase().split(new RegExp(`(${state.search.toLowerCase()})`, 'gi')).length > 1,
                  ),
            );
         }
         return state.advicesHidden;
      },

      getTitle: state => state.title,

      getEditMode: state => state.editMode,

      getCurrentFontSize: state => state.fontSize,

      getLoading: state => state.isDataLoading,

      getPlay: state => state.play,

      getIdOfPlayingAdvice: state => state.idOfPlayingAdvice,

      getIsModalOpen: state => state.isModalOpen,

      getAdvicesToPlay: state => {
         //! here might be a problem
         if (state.search) {
            return state[state.isHiddenMode ? 'advicesHidden' : 'advices'].filter(({ text }) =>
               Boolean(text.toLowerCase().match(state.search)),
            );
         } else {
            return state[state.isHiddenMode ? 'advicesHidden' : 'advices'];
         }
      },

      getEditItems: state => state.editItems,

      getUnvisible: state => state.unvisible,
      getUnvisibleHabits: state => state.unvisibleHabits,

      getIsCategoriesLoaded: state => state.isCategoriesLoaded,

      getIsSearch: state => state.isSerach,

      getIsPlayerPlaying: state => state.isPlayerPlaying,

      getIsUpdatedScroll: state => state.updateScroll,

      getPlayerIndex: state => state.playerIndex,

      getIsPlayDelimeter: state => state.isPlayDelimeter,

      getListOfHiddenAdvicesForSelectedCategory: state => {
         let res = [];
         if (state.selectedCategory === CATEGORY_ALL) {
            state.listOfHiddenAdvicesForSelectedCategory.forEach(adviceList => {
               if (adviceList.code === CATEGORY_TO_EXCLUDE) return;
               res = [...res, ...adviceList?.advices];
            });
         } else {
            res = state.listOfHiddenAdvicesForSelectedCategory.filter(el => state.selectedCategory === el.code)?.[0]
               ?.advices;
         }

         return res ? cloneDeep(res) : [];
      },
      getListOfHiddenAdvicesForSelectedCategoryHabits: state => {

         return state.hiddenHabits;
      },

      getIsHabitsRoute: state => state.isHabitsRoute,
   },

   mutations: {
      setCategoryNameToEdit(state, payload) {
         state.categoryNameToEdit = payload;
      },

      setVisibleItems(state, payload) {
         state.visibleItems = payload;
      },

      setVisibleItemsHabits(state, payload) {
         state.visibleItemsHabits = payload;
      },

      [mutations.FIND_CATEGORY](state, id) {
         state.category = state.categories.filter(item => item._id == id)[0];
      },

      [mutations.SET_HIDDEN_MODE](state, payload) {
         state.isHiddenMode = payload;
      },

      [mutations.SET_HIDDEN_MODE_HABITS](state, payload) {
         state.isHiddenModeHabits = payload;
      },

      [mutations.FIND_SUBCATEGORIES](state, parentCode) {
         state.subcategories = state.categories.filter(item => item.parent === parentCode && !item.text);
         state.hiddenSubcategories = state.hiddenCategories.filter(item => item.parent === parentCode && !item.text);
      },

      [mutations.FIND_ADVICES](state /*parentCode = null*/) {
         const categories = state.isHiddenMode ? [...state.hiddenCategories] : [...state.categories];
         const mainCategories = [...state.categories.filter(c => c.code && !c.parent)];
         const res = mainCategories.map(category => {
            let subCategories = state.categories.filter(c => c.parent === category.code && c.title);
            let advices = categories.filter(c => c.parent === category.code && c.text);

            if (subCategories.length > 0) {
               subCategories = subCategories.map(ad => {
                  let res = { ...ad };
                  res.advices = [...categories.filter(c => c.parent === ad.code)];
                  return res;
               });
               subCategories = subCategories.filter(sub => sub?.advices?.length > 0);
            }

            return { ...category, advices: [...advices, ...subCategories] };
         });

         state.advices = [...res];

         /* if (!parentCode) {
            state.advices = state.categories.filter(item => item.text);
         } else {
            state.advices = state.categories.filter(item => item.parent === parentCode && item.text);
            state.advicesHidden = state.hiddenCategories.filter(item => item.parent === parentCode && item.text);
         } */
      },

      [mutations.FIND_HABITS](state /*parentCode = null*/) {
         const categories = state.isHiddenModeHabits ? [...state.hiddenHabits] : [...state.habits];
         // console.log('🚀 ~ file: advice.js:410 ~ categories:', categories);
        // const mainCategories = [...state.categories.filter(c => c.parent === CATEGORY_TO_EXCLUDE)];
         /*      const res = mainCategories.map(category => {
            let habits = categories.filter(c => c.parent === CATEGORY_TO_EXCLUDE);


            return { ...category, advices: [...habits, ] };
         }); */

         state.habits = categories;

         /* if (!parentCode) {
            state.advices = state.categories.filter(item => item.text);
         } else {
            state.advices = state.categories.filter(item => item.parent === parentCode && item.text);
            state.advicesHidden = state.hiddenCategories.filter(item => item.parent === parentCode && item.text);
         } */
      },

      setListOfHidden(state) {
         const categoriesHidden = [...state.hiddenCategories];
         const mainCategoriesForHidden = [...state.categories.filter(c => c.code && !c.parent)];
         const resHidden = mainCategoriesForHidden.map(category => {
            let subCategories = state.categories.filter(c => c.parent === category.code && c.title);
            let advices = categoriesHidden.filter(c => c.parent === category.code && c.text);

            if (subCategories.length > 0) {
               subCategories = subCategories.map(ad => {
                  let res = { ...ad };
                  res.advices = [...categoriesHidden.filter(c => c.parent === ad.code)];
                  return res;
               });
               subCategories = subCategories.filter(sub => sub?.advices?.length > 0);
            }

            return { ...category, advices: [...advices, ...subCategories] };
         });
         state.listOfHiddenAdvicesForSelectedCategory = [...resHidden];
      },

      setListOfHiddenHabits(state) {
         const categoriesHidden = [...state.hiddenHabits];
         state.listOfHiddenAdvicesForSelectedCategoryHabits = [...categoriesHidden];
      },

      [mutations.UPDATE_TITLE](state, title) {
         state.title = title;
      },

      [mutations.UPDATE_EDITE_MODE](state, isEnable) {
         state.editMode = isEnable;
      },

      [mutations.UPDATE_THEME](state, theme) {
         state.theme = theme;
      },

      [mutations.UPDATE_FONT_SIZE](state, font) {
         state.fontSize = font;
      },

      [mutations.UPDATE_CATEGORIES](state, data) {
         state.categories = [...data];
      },

      [mutations.UPDATE_HABITS](state, data) {
         state.habits = [...data];
      },

      [mutations.UPDATE_HIDDEN_CATEGORIES](state, data) {
         state.hiddenCategories = data;
      },

      [mutations.UPDATE_HIDDEN_HABITS_CATEGORIES](state, data) {
         state.hiddenHabits = data;
      },

      [mutations.UPDATE_LOADING](state, data) {
         state.isDataLoading = data;
      },

      [mutations.UPDATE_SEARCH](state, data) {
         state.search = data;
      },

      [mutations.SET_IS_SEARCH](state, data) {
         state.isSerach = data;
      },

      [mutations.UPDATE_PLAY](state, data) {
         state.play = data;
      },

      [mutations.UPDATE_IS_PLAYER](state, data) {
         state.isPlayerPlaying = data;
      },

      [mutations.SET_ID_OF_PLAYING_ADVICE](state, id) {
         state.idOfPlayingAdvice = id;
      },

      [mutations.UPDATE_UNVISIBLE](state, ids) {
         state.unvisible = ids;
      },

      [mutations.UPDATE_UNVISIBLE_HABITS](state, ids) {
         state.unvisibleHabits = ids;
      },

      [mutations.UPDATE_IS_HABITS_ROUTE](state, val) {
         state.isHabitsRoute = val;
      },

      [mutations.REMOVE_UNVISIBLE](state, ids) {
         let clearIds = [...state.unvisible];
         for (let id of ids) {
            if (clearIds.includes(id)) {
               clearIds.splice(clearIds.indexOf(id), 1);
            }
         }
         state.unvisible = clearIds;
      },

      [mutations.SET_IS_MODAL_OPEN](state, payload) {
         state.isModalOpen = payload;
      },

      [mutations.UPDATE_EDIT_ITEMS](state, payload) {
         state.editItems = [...payload];
      },

      updateCategoriesLoaded(state, payload) {
         state.isCategoriesLoaded = payload;
      },

      [mutations.SAVE_UNVISIBLE_TO_TEMP](state) {
         state.tempUnvisible = [...state.unvisible];
      },

      [mutations.SET_IS_CONFIRM_ADVICES](state, payload) {
         if (
            state.unvisible.length === state.tempUnvisible.length &&
            state.unvisible.every((val, index) => val === state.tempUnvisible[index])
         ) {
            state.isConfirmAdvices = false;
         } else {
            state.isConfirmAdvices = payload;
         }
      },

      updateScroll(state, payload) {
         state.updateScroll = payload;
      },

      [mutations.UPDATE_PLAYER_INDEX](state, payload) {
         state.playerIndex = payload;
      },

      [mutations.PLAY_DELIMETER](state, payload) {
         state.isPlayDelimeter = payload;
      },

      [mutations.UPDATE_SELECTED_CATEGORY](state, payload) {
         state.selectedCategory = payload;
      },

      updateIsEditMenuOpen(state, payload) {
         state.isEditMenuOpen = payload;
      },

      updateIsEditHabitsMenuOpen(state, payload) {
         state.isEditHabitsMenuOpen = payload;
      },
      updateUsersLists(state, payload) {
         state.userLists = payload;
      },

      udpateSelected(state, payload) {
         state.selected = payload;
      },

      udpateSelectedHabits(state, payload) {
         state.selectedHabits = payload;
      },

      updateProfile(state, payload) {
         state.profile = payload;
      },

      updateIsCategoriesListOpen(state, payload) {
         state.isCategoriesListOpen = payload;
      },
   },

   actions: {
      async [actions.FETCH]({ commit }) {
         commit(mutations.UPDATE_LOADING, true);
         commit('updateCategoriesLoaded', false);

         try {
            const profile = await http.post('resources/profile');
            commit('updateProfile', profile.data);
            if (profile) {
              // const usersLists = await http.post('resources/lists', { _id: profile.data._id });
               //commit('updateUsersLists', usersLists.data);
            }
          /*   let data = await http.post('resources/categories');
            commit(mutations.UPDATE_CATEGORIES, data.data.visible);
            commit(mutations.UPDATE_HIDDEN_CATEGORIES, data.data.unvisible);
            commit(mutations.FIND_ADVICES);
            commit('setListOfHidden');
            let idsUnvisible = data.data.unvisibleIds.map(({ _id }) => _id);
            commit(mutations.UPDATE_UNVISIBLE, idsUnvisible); */
         } catch (err) {
            if (
               err?.data?.message?.message?.message === JWT_EXPIRED ||
               err?.data?.message?.message?.message === JWT_MUST_PROVIDED
            ) {
               //router.push({ name: 'app-login' });
            } else if (err?.data?.message === END_OF_TRIAL_SUBSCRIPTION) {
               router.push({ name: 'app-profile', params: { isOpenSubscription: true } });
            } else {
               console.log(err);
            }
         } finally {
            //commit('updateCategoriesLoaded', true);
            //commit(mutations.UPDATE_LOADING, false);
         }
      },

    /*   async [actions.FETCH_HABITS]({ commit }) {
         console.log('action FETCH HABITS');
         commit(mutations.UPDATE_LOADING, true);
         commit('updateCategoriesLoaded', false);

         try {
            const profile = await http.post('resources/profile');
            commit('updateProfile', profile.data);
            if (profile) {
               const usersLists = await http.post('resources/lists', { _id: profile.data._id });
               commit('updateUsersLists', usersLists.data);
            }

            let data = await http.post('resources/categories', { isHabits: true });
            console.log('🚀 ~ file: advice.js:658 ~ data:', data);
            const habits = data.data.visible.filter(c => c.parent === CATEGORY_TO_EXCLUDE);
            console.log('🚀 ~ file: advice.js:640 ~ habits:', habits);

            commit(mutations.UPDATE_HABITS, habits);
            console.log(data.data.unvisible);
            commit(mutations.UPDATE_HIDDEN_HABITS_CATEGORIES, data.data.unvisible); //! here is a problem
            commit(mutations.FIND_HABITS);
         
         //   commit('setListOfHiddenHabits');
            commit('setListOfHidden');
            let idsUnvisible = data.data.unvisibleIds.map(({ _id }) => _id);
            console.log('🚀 ~ file: advice.js:682 ~ idsUnvisible:', idsUnvisible);
            commit(mutations.UPDATE_UNVISIBLE_HABITS, idsUnvisible);
         } catch (err) {
            if (
               err?.data?.message?.message?.message === JWT_EXPIRED ||
               err?.data?.message?.message?.message === JWT_MUST_PROVIDED
            ) {
               //router.push({ name: 'app-login' });
            } else if (err?.data?.message === END_OF_TRIAL_SUBSCRIPTION) {
               router.push({ name: 'app-profile', params: { isOpenSubscription: true } });
            } else {
               console.log(err);
            }
         } finally {
            commit('updateCategoriesLoaded', true);
            commit(mutations.UPDATE_LOADING, false);
         }
      }, */

      async updateUnvisibleHabits({ commit, dispatch }, unvisible) {
         commit(mutations.UPDATE_UNVISIBLE_HABITS, unvisible);
         const response = await http.put('resources/profile/unvisible', { unvisible: unvisible });
         if (response.status === 200) {
            if (navigator.serviceWorker.controller) {
               navigator.serviceWorker.controller.postMessage({
                  type: 'clearCategories',
               });
            }
            dispatch(actions.FETCH_HABITS);
         }
      },

      async updateUnvisible({ commit, dispatch }, unvisible) {
         commit(mutations.UPDATE_UNVISIBLE, unvisible);
         const response = await http.put('resources/profile/unvisible', { unvisible: unvisible });
         if (response.status === 200) {
            if (navigator.serviceWorker.controller) {
               navigator.serviceWorker.controller.postMessage({
                  type: 'clearCategories',
               });
            }

            dispatch(actions.FETCH);
         }
      },

      updateData({ commit, state }) {
         if (router.history.current.name === 'app-main-subcategory') {
            commit(mutations.FIND_CATEGORY, router.history.current.params.id);
            commit(mutations.UPDATE_TITLE, state.category.title);
            commit(mutations.FIND_ADVICES, state.category.code);
         } else if (router.history.current.name === 'app-main-category') {
            commit(mutations.FIND_CATEGORY, router.history.current.params.id);
            commit(mutations.UPDATE_TITLE, state.category.title);
            commit(mutations.FIND_ADVICES, state.category.code);
            commit(mutations.FIND_SUBCATEGORIES, state.category.code);
         } else {
            commit(mutations.UPDATE_TITLE, 'Sleep Well');
            commit(mutations.FIND_ADVICES);
         }
      },

      doNotSaveAdvices({ state, dispatch }) {
         dispatch('updateUnvisible', state.tempUnvisible);
      },
   },
};

export default store;
